/* You can add global styles to this file, and also import other style files */
.usa-button:focus {
  outline: none !important;
}

.error-message{
  color: red;
}
 /* Fancy shadow and border for grid form */
 .table-grid {
  overflow-x: scroll;
  width: 100%;
  max-width: 1200px; /* Limit the max width for larger screens */
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
  justify-items: center;
  margin-bottom: 20px;
  padding: 20px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 
              0 6px 20px rgba(0, 0, 0, 0.1);
  border: 1px solid #ddd;
}
/* for min 800 */
@media (max-width: 800px) {
  .usa-button{
    margin-bottom: 10px;
  }
}
.align-start {
  align-self: flex-start;
}
/* Center the grid-container and make it full width */
/* .grid-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
} */
/* Full width for the table inside the grid */
.full-width-table {
  width: 100%;
  border-collapse: collapse;
  text-align: center;
}
/* Table headers styling */
th {
  padding: 10px;
  text-transform: uppercase;
}

td {
  padding: 10px;
}

/* Alternating row colors for readability */
tbody tr:nth-child(odd) {
  background-color: #f2f2f2;
}

tbody tr:nth-child(even) {
  background-color: #fff;
}

.max-width-column {
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.usa-prose>table td,
.usa-prose>table th,
.usa-table td,
.usa-table th {
  text-align: left !important;
}

button[disabled] {
  background-color: #ccc;
  cursor: not-allowed;
}

.delete-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.delete-modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  width: 400px;
  text-align: center;
}

.delete-modal-actions {
  margin-top: 20px;
  display: flex;
  justify-content: space-around;
}
.navigation {
  display: block;
  float: left;
  padding-right: 0;
  padding-left: 2rem;
  margin-left: 1rem;
  width: 15rem;
  height: 100%;
  /* top: 4em; */
}

.navigation{
  font-family: Public Sans Web, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  font-size: .94rem;
  line-height: 1.4;
  background-color: #fff;
  border: 2px solid rgba(0, 0, 0, .1);
  border-radius: .25rem;
  padding: 0;
}
.sidenav-container{
  width: 100%;
  max-width: 1080px;
}
.filter-grid {
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 20px;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 1200px;
  margin-bottom: 20px;
}
.table-grid {
  width: 100%;
  max-width: 1200px;
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1),
              0 6px 20px rgba(0, 0, 0, 0.1);
}
.full-width-table {
  width: 100%;
  border-collapse: collapse;
  text-align: center;
}
th {
  background-color: #4CAF50;
  color: white;
  padding: 10px;
  text-transform: uppercase;
  white-space: nowrap !important;
}
td {
  padding: 10px;
  white-space: nowrap !important;
}
tbody tr:nth-child(odd) {
  background-color: #f2f2f2;
}
tbody tr:nth-child(even) {
  background-color: #fff;
}
.max-width-column {
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.usa-prose > table td,
.usa-prose > table th,
.usa-table td,
.usa-table th {
  text-align: left !important;
}
.usa-input {
  padding: 10px;
  border-radius: 5px;
  width: 100%;
  max-width: 400px;
  border: 1px solid #ccc;
}
.usa-button {
  padding: 10px 20px;
}
.usa-button--small,
.usa-button-small {
  padding: 4px;
}
.align-start {
  align-self: flex-start;
}
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}
.modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  width: auto;
  max-width: 800px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}
.grid-container > .usa-heading {
  text-align: center;
}
